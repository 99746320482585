import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('../views/HomePage.vue')
    },
    {
        path: '/view-journey/:id',
        name: 'ViewJourney',
        component: () => import('../views/ViewJourney.vue')
    },
    {
        path: '/matn-journey/:id',
        name: 'MatnJourney',
        component: () => import('../views/MatnJourney.vue')
    },
    {
        path: '/matn-stop-points/:id',
        name: 'MatnStopPoints',
        component: () => import('../views/MatnStopPoints.vue')
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import('../views/LoginPage.vue')
    },
    {
        path: '/api',
        meta: {
            ignoreRoute: true
        }
    }
];

const router = new VueRouter({
    mode: 'hash',
    routes
});

router.beforeEach((to, from, next) => {
    if(to.meta && to.meta.ignoreRoute) {
        next(false)
    } else {
        next()
    }
})

export default router;